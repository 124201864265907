import React, { useRef, useEffect, useState } from 'react';
import HCaptcha from "@hcaptcha/react-hcaptcha";

const SITE_KEY = process.env.HCAPTURE_PUBLIC_KEY || "10000000-ffff-ffff-ffff-000000000001";
window.hCaptureLoaded = false;

export default function CaptureChallenge(props) {
  const [captchaRef, setCaptchaRef] = useState();
  const [loaded, setIsLoaded] = useState(window.hCaptureLoaded);

  useEffect(() => {
    if (props.visible && loaded && captchaRef) {
      captchaRef.execute();
    }
  }, [props.visible, loaded, captchaRef]);

  if (!props.visible) {
    return <></>;
  }

  const hCaptureLoaded = () => {
    window.hCaptureLoaded = true;
    setIsLoaded(true);
  }

  return (
    <>
      <HCaptcha
        sitekey={SITE_KEY}
        onLoad={hCaptureLoaded}
        onVerify={props.onVerify}
        ref={ref => setCaptchaRef(ref)}
      />
      </>
  );
}