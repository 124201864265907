import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Toaster } from 'react-hot-toast';
import Breakout from './Breakout';
import { StoreProvider } from './Store';
import { installFatZebraListener } from './fatZebraIFrame';

console.log(`Starting ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);

window.beforeInstallPromptEvent = null;
window.addEventListener('beforeinstallprompt', (e) => {
  if (window.location.pathname !== "/") {
    // Don't show the install prompt on pages other than the home page
    e.preventDefault();
    window.beforeInstallPromptEvent = e;
  }
});

const ua = navigator.userAgent || navigator.vendor || window.opera;
const campaignSlug = window.location.pathname.slice(1);
const timezone = -(new Date()).getTimezoneOffset() / 60;

if (ua.match(/Android.*; ?wv/ig)) { // Simplified, only works on Android 5+ now
  console.log('Android Webview detected');
  ReactDOM.render(
    <Breakout />,
    document.getElementById('root')
  );
  window.location.href = `intent:${window.location.href}#Intent;action=android.intent.action.VIEW;end;`;
} else {
  ReactDOM.render(
    <StoreProvider>
      <App />
      <Toaster />
    </StoreProvider>,
    document.getElementById('root')
  );
  
  installFatZebraListener();
  
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('sw.js');
  }
}
