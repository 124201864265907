// import GetPaymentRequest from './GetPaymentRequest';
import PayConfig from './PayConfig';
import GetGooglePaymentsClient from './GetGooglePaymentsClient';

function getRaceTimer(time) {
  return new Promise((r) => setTimeout(() => {
    r('timeout');
  }, time));
}

async function GetPaymentOptions(charityConfig, charity) {
  let options = ['form'];

  if (!charityConfig.googleMerchantId) {
    console.warning(`Skipping Google Pay detecting due to no googleMerchantId.`);
  } else {
    let googlePaymentRequest = PayConfig.getGooglePay(charity.name, charityConfig);
    googlePaymentRequest.existingPaymentMethodRequired = true;
    googlePaymentRequest.transactionInfo = {
      totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
      currencyCode: charity.currency || 'AUD',
    }
    const googlePayClient = GetGooglePaymentsClient();
    const isReadyToGooglePay = await Promise.race([
      googlePayClient.isReadyToPay(googlePaymentRequest),
      getRaceTimer(2000),
    ]);
    
    if (isReadyToGooglePay === 'timeout') {
      console.warn('isReadyToPay() check never returned');
      options.push('googlejs');
    } else if (isReadyToGooglePay.result) {
      if (isReadyToGooglePay.paymentMethodPresent) {
        // Note: paymentMethodPresent is always true in TEST environment
        // Google Pay ready to pay
        console.info(`GooglePay supported with active card.`);
        options.unshift('googlejs');
        googlePayClient.prefetchPaymentData(googlePaymentRequest);
      } else {
        // Google Pay not ready to pay
        console.info(`GooglePay supported with no active card.`);
        options.push('googlejs');
      }
    } else {
      console.info(`GooglePay not supported`);
    }
  }

  // Note: The below has been left commented in case we want to explicitly
  // support the PaymentRequest for Google and/or others in future
  //   if (window.PaymentRequest) {
  //     const paymentRequest = GetPaymentRequest('Merchant', charityConfig, 'Provider Check', 10);
  //     let supported = paymentRequest.hasEnrolledInstrument
  //       ? await paymentRequest.hasEnrolledInstrument()
  //       : await paymentRequest.canMakePayment();

  //     if (supported && !window.ApplePaySession) {
  //       console.info(`PaymentRequest API has card available.`);
  //       options.unshift('google');
  //     } else {
  //       console.info(`No enrolled cards available.`)
  //     }
  //   }

  // TODO: Use ApplePaySession for payment and remove PaymentRequest check
  // Do Apple after Google so it always ends up first when a card is available
  if (window.PaymentRequest && window.ApplePaySession) {
    if (!charityConfig.appleMerchantId) {
      // Charity doesn't have ApplyPay setup
      console.warning(`ApplePay detected but no appleMerchantId.`);
    } else if (!window.ApplePaySession.canMakePayments()) { 
      // Device doesn't support apple pay
      console.info(`ApplePay detected but canMakePayments() returned false.`);
    } else {
      // Device supports apple pay
      let applePayHasCard = await window.ApplePaySession.canMakePaymentsWithActiveCard(charityConfig.appleMerchantId);
      if (!applePayHasCard) {
        // No card available
        console.info(`ApplePay detected but canMakePaymentsWithActiveCard() returned false.`);
        options.push('apple');
      } else {
        // Card available
        console.info('ApplePay detected and canMakePaymentsWithActiveCard() returned true.')
        options.unshift('apple');
      }
    }
  }

  if (charityConfig.appleMerchantId) {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);  
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !/ \w+iOS/.test(ua); // ie: not CriOS, FxiOS, OPiOS
    if (iOS && !iOSSafari) {
      options.push('safari');
    }
  }

  if (!window.PaymentRequest) {
    console.info(`PaymentRequest API not available.`);
  }

  return options;
}

export default GetPaymentOptions;
