import React from 'react';
import { getPageBySlug } from './api';

import isProduction from './IsProduction';

export const Store = React.createContext();

const initialState = {
  initialised: false,
  loading: false,
  campaign: null,
  hideCampaignScreen: false,
  isProduction: isProduction,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SHOW_BARCODE_SCREEN':
      return { ...state, currentPage: 'barcode' };
    case 'LOADING_CAMPAIGN':
      return { ...state, currentPage: 'loading' };
    case 'LOAD_CAMPAIGN':
      return {
        ...state,
        currentPage: 'campaign',
        // Overwrite charity details from campaign if present
        charity: Object.assign({}, action.charity, action.campaign.charityDetails || {}),
        // Merge config from charity, with campaign overwriting
        config: Object.assign({}, action.charity.config, action.campaign.config || {}),
        campaign: action.campaign,
        statistics: action.statistics,
        charityKey: action.charityKey,
        campaignKey: action.campaignKey,
        campaignSlug: action.campaignSlug,
      };
    case 'LOAD_CAMPAIGN_FAILED':
      return { ...state, currentPage: 'error', error: action.error };
    case 'LOADING_THANKYOU':
      return {
        ...state,
        currentPage: 'thank-you',
        amount: action.amount,
        donorEmail: action.donorEmail,
        transactionReference: action.transactionReference,
        paymentResponseKey: action.paymentResponseKey,
      };
    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };
  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}

export const loadCampaign = async (dispatch, campaignSlug) => {
  try {
    dispatch({
      type: 'LOADING_CAMPAIGN',
    });

    const campaignDetails = await getPageBySlug(campaignSlug);

    return dispatch({
      type: 'LOAD_CAMPAIGN',
      campaignSlug,
      ...campaignDetails,
    });
  } catch (e) {
    console.log(e);
    return dispatch({
      type: 'LOAD_CAMPAIGN_FAILED',
      error: 'That campaign could not be found. Please check the URL or try again later.',
    });
  }
};
