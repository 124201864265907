import React from 'react';
import './Breakout.scss';

import logoBlack from './Assets/img/logoBlack.png';
import logoBlack2x from './Assets/img/logoBlack@2x.png';
import logoBlack3x from './Assets/img/logoBlack@3x.png';
import questLogoBlack from './Assets/img/questLogoBlack.png';
import questLogoBlack2x from './Assets/img/questLogoBlack@2x.png';
import questLogoBlack3x from './Assets/img/questLogoBlack@3x.png';

function Breakout() {

  return (
    <div className="breakout">
      <div>
        <img
          src={logoBlack}
          srcSet={`${logoBlack} 1x, ${logoBlack2x} 2x, ${logoBlack3x} 3x`}
          className="dpg-logo-black"
          alt="Dpgo"
        />
      </div>
      <p>
        For the best experience, please launch<br />
        <b>Donation Point Go</b><br />
        in a <a className="primary-color" href={`intent:${window.location.href}#Intent;action=android.intent.action.VIEW;end;`}>Web Browser</a> such 
        as <a className="primary-color" href={`intent:${window.location.href}#Intent;package=com.android.chrome;end;`}>Chrome</a>.
      </p>
      <div>
        <img alt="Powered by Quest Payment Systems" className="quest-logo"
          src={questLogoBlack}
          srcset={`${questLogoBlack} 1x, ${questLogoBlack2x} 2x, ${questLogoBlack3x} 3x`} />
      </div>
    </div>
  );
}

export default Breakout;