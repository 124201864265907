import React from 'react';
import './Stats.scss';
import FormatCurrency from './Utils/FormatCurrency';

function Stats(props) {
  const { targetPercentageAchieved, totalDonatedAmount, totalDonations } = props.statistics;
  //set the max width of the progress bar to 100% and continue to disply the actual targetPercentage in text
  const progressPercentage = parseInt(targetPercentageAchieved) > 100 ? 100 : targetPercentageAchieved;

  const target = props.campaign.target && props.campaign.target !== 0 ? props.campaign.target : null;
  return (
    <div className="Stats center">
      {target && (
        <div className="flex">
          <div className="grow progress-container">
            <div className="progress-bar primary-bg" style={{ width: `${progressPercentage}%`}}></div>
          </div>
          <div className="progress-text">{targetPercentageAchieved}%</div>
        </div>
      )}

      <div className="flex">
        <div className="grow donations">
          <div className="title">Donations</div>
          <div className="value">{totalDonations}</div>
        </div>
        <div className="grow total">
          <div className="title">Total</div>
          <div className="value">{FormatCurrency(totalDonatedAmount, props.currency, props.locale)}</div>
        </div>
        {target && (
          <div className="grow target">
            <div className="title">Target</div>
            <div className="value">{FormatCurrency(target, props.currency, props.locale)}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Stats;
