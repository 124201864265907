import React, { useState, useRef } from 'react';
import toast from 'react-hot-toast';
import ReactGA from 'react-ga';
import AnimateHeight from 'react-animate-height';
import './Choices.scss';
//Assets set for choice window
import heart from './Assets/img/heart.png';
import heart2x from './Assets/img/heart@2x.png';
import heart3x from './Assets/img/heart@3x.png';
import hand from './Assets/img/hand.png';
import hand2x from './Assets/img/hand@2x.png';
import hand3x from './Assets/img/hand@3x.png';
import cross from './Assets/img/cross.png';
import cross2x from './Assets/img/cross@2x.png';
import cross3x from './Assets/img/cross@3x.png';

import { Store } from './Store';
import googlePayMark from './Assets/img/google-pay-mark.svg';
import applePayMark from './Assets/img/apple-pay-mark.svg';
import formPaymentMark from './Assets/img/credit-card.svg';
import GetPaymentRequest from './Payments/GetPaymentRequest';
import FormatCurrency from './Utils/FormatCurrency';
import { CreditCardForm } from './CreditCardForm';
import copyrightImage from './Assets/img/copyright.svg';
import * as uuid from 'uuid';

import LoadingCubes from './LoadingCubes';

import GetGooglePaymentRequest from './Payments/GetGooglePaymentRequest';
import GetGooglePaymentsClient from './Payments/GetGooglePaymentsClient';
import PaymentTypePicker from './PaymentTypePicker';
import { getRecaptchaToken } from './api';
import CaptureChallenge from './CaptureChallenge';

const ANIMATION_LENGTH = 300;

function Choices(props) {
  const { state, dispatch } = React.useContext(Store);
  const charityKey = state.charityKey;
  const campaignKey = state.campaignKey;
  const donationPresets = Object.values(state.campaign.donationPresets);
  const [choosePaymentOptionVisible, setChoosePaymentOptionVisible] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [shownApplePayToast, setShownApplePayToast] = useState(false);
  const [customAmountContainer, setCustomAmountContainer] = useState(false);
  const [customAmount, setCustomAmount] = useState(state.campaign.defaultCustomAmount || '10.00');
  const [errorMessage, setErrorMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const selectedItem = useRef();
  const selectedAmount = useRef();
  const [showExtraCapture, setShowExtraCapture] = useState(false);
  const lastCompletePaymentArgs = useRef();

  // to show the credit card form specify a positive amount
  const [creditCardFormAmount, setCreditCardFormAmount] = useState(0);
  const hideCreditCardForm = () => setCreditCardFormAmount(0);
  const clearErrorMessage = () => setErrorMessage('');

  React.useEffect(() => {
    if (modalVisible && props.paymentOptions && props.paymentOptions.includes('safari') && !shownApplePayToast) {
      toast(<span>To use Apple Pay, please open this page in Safari</span>, {
        icon: <img src={applePayMark} alt="Apple Pay" width="48" />,
        duration: 3000,
      });
      setShownApplePayToast(true);
    }
  }, [modalVisible, props.paymentOptions, shownApplePayToast]);

  function updatePaymentOption(option) {
    setSelectedPaymentOption(option);
    setChoosePaymentOptionVisible(false);
    clearErrorMessage();
  }

  const paymentFailed = (error = 'Payment Failed. Please try again.') => {
    hideCreditCardForm();
    openModal();
    setProcessing(false);
    if (error) {
      setErrorMessage(error);
    }
  };

  const paymentComplete = (success, item, amount, transactionReference, donorEmail, result = {}) => {
    hideCreditCardForm();
    setProcessing(false);

    ReactGA.event(
      {
        category: 'Donation',
        action: `Donation ${success ? 'complete' : 'failed'}`,
        label: item,
        value: amount,
        dimension3: getSelectedPaymentOption(),
        dimension5: state.charity.currency || 'AUD',
      },
      ['charity']
    );

    console.log('Payment completed with ' + success);
    if (success) {
      closeModal();
      dispatch({
        type: 'LOADING_THANKYOU',
        amount,
        donorEmail,
        transactionReference,
        paymentResponseKey: result.paymentResponseKey,
      });
    } else {
      paymentFailed(`Payment Failed. ${result.message || 'Please try again.'}`);
    }
  };

  function updateCustomAmount(amount) {
    if (!/^\d*(\.\d{0,2})?$/.test(amount)) {
      return false;
    }
    if (amount > 9999) {
      setCustomAmount('9999');
      return false;
    }
    setCustomAmount(amount);
  }

  React.useEffect(() => {
    if (customAmountContainer) {
      // Create a fake input to open the keyboard early
      var __tempEl__ = document.createElement('input');
      __tempEl__.style.position = 'absolute';
      __tempEl__.style.bottom = 0;
      __tempEl__.style.left = 0;
      __tempEl__.style.height = 0;
      __tempEl__.style.opacity = 0;
      __tempEl__.inputMode = 'numeric';
      document.body.appendChild(__tempEl__);
      __tempEl__.focus();

      setTimeout(() => {
        let el = document.getElementById('custom-amount');
        if (customAmountContainer && el) {
          el.focus();
          el.select();
        }
        // Remove temp input
        document.body.removeChild(__tempEl__);
      }, ANIMATION_LENGTH + 1);
    }
  }, [customAmountContainer]);

  function getSelectedPaymentOption() {
    return selectedPaymentOption || props.paymentOptions[0];
  }

  function getDonateWithElement() {
    switch (getSelectedPaymentOption()) {
      case 'apple':
        return (
          <p className="header-text">
            Donate with <img src={applePayMark} alt="Apple Pay" width="48" />
          </p>
        );
      case 'googlejs':
        return (
          <p className="header-text">
            Donate with <img src={googlePayMark} alt="Google Pay" width="48" />
          </p>
        );
      case 'google':
        return <p className="header-text">Donate Now</p>;
      default:
        return (
          <p className="header-text">
            Donate with <img src={formPaymentMark} alt="Credit Card" width="48" />
          </p>
        );
    }
  }

  function openModal() {
    setModalVisible(true);
  }

  function closeModal() {
    clearErrorMessage();
    setProcessing(false);
    setCustomAmountContainer(false);
    setChoosePaymentOptionVisible(false);
    setModalVisible(false);
  }

  async function doPayment(item, amount) {
    item = item || `Gift of ${FormatCurrency(amount, state.charity.currency || 'AUD', state.charity.locale || 'en-AU')}`;
    amount = Number(amount);
    selectedItem.current = item;
    selectedAmount.current = amount;

    if (!amount) {
      alert('Please enter a valid amount to donate.');
      return;
    }

    const paymentType = getSelectedPaymentOption();

    ReactGA.event(
      {
        category: 'Donation',
        action: 'Donation started',
        label: item,
        value: amount,
        dimension3: paymentType,
        dimension5: state.charity.currency || 'AUD',
      },
      ['charity']
    );

    if (paymentType === 'form') {
      closeModal();
      setCreditCardFormAmount(amount);
      return;
    }

    setProcessing(true);
    await payWithWallet(item, amount);
  }

  async function onExtraCaptureVerify(hcapture) {
    try {
      setShowExtraCapture(false);
      await completePayment(...lastCompletePaymentArgs.current, hcapture);
    } catch (e) {
      console.warn(e);
    }
  }

  async function creditCardFormComplete(args) {
    const approved = args.r === '1';

    openModal();
    setProcessing(true);
    hideCreditCardForm();

    const reference = uuid.v1();
    let item = selectedItem.current;
    let amount = selectedAmount.current;
    if (approved) {
      await completePayment(
        item,
        amount,
        args.card_holder,
        args.email,
        reference,
        'creditCard',
        args.token,
        args.hcapture
      );
    } else {
      paymentComplete(false, item, amount, reference, args.email);
    }
  }

  /**
   * Details required for a complete payment.
   * @param {*} item Donation preset chosen for payment
   * @param {*} amount Donation amount
   * @param {*} name Card holder name
   * @param {*} email Card holder email
   * @param {*} id payment reference for the transcation
   * @param {*} methodName payment method
   * @param {*} details payment response
   */
  async function completePayment(item, amount, name, email, id, methodName, token, hcapture) {
    lastCompletePaymentArgs.current = [item, amount, name, email, id, methodName, token];
    const paymentResponse = await fetch('/api/payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        payerEmailID: email,
        payerName: name,
        requestID: id,
        token,
        methodName: methodName,
        item: item,
        amount,
        currency: state.charity.currency || 'AUD',
        charity: charityKey || state.charityKey,
        campaign: campaignKey || state.campaignKey,
        ...(hcapture ? { hcapture } : { recaptcha: await getRecaptchaToken() }),
      }),
    });

    if (paymentResponse.status < 200 || paymentResponse.status > 300) {
      ReactGA.exception({
        description: `Code: ${paymentResponse.status} Error: ${paymentResponse.statusText}`,
      });

      const isCaptchaRejected = paymentResponse.status === 403;
      if (isCaptchaRejected) {
        setShowExtraCapture(true);
      } else {
        paymentFailed(`Payment Failed. Please try again. (${paymentResponse.status})`);
      }
      return;
    }

    const result = await paymentResponse.json();
    const success = result.successful === true;

    paymentComplete(success, item, amount, id, email, result);

    return success;
  }

  async function payWithWallet(item, amount) {
    let payerName, payerEmail, requestId, methodName, details;
    if (getSelectedPaymentOption() === 'apple' || getSelectedPaymentOption() === 'google') {
      const paymentRequest = GetPaymentRequest(state.config, state.charity, item, amount);
      paymentRequest.onmerchantvalidation = function (event) {
        console.log(`Performing a merchant validation with the following URL: ${event.validationURL}`);

        const encodedCharity = encodeURIComponent(charityKey);
        const encodedMerchantId = encodeURIComponent(state.config.appleMerchantId);
        const encodedValidationUrl = encodeURIComponent(event.validationURL);
        const url = `/api/startPaymentSession?charity=${encodedCharity}&merchantId=${encodedMerchantId}&validationURL=${encodedValidationUrl}`;
        let sessionPromise = fetch(url).then((res) => {
          if (!res.ok) {
            throw res.statusText;
          }
          return res.json().then((json) => {
            return json;
          });
        });
        event.complete(sessionPromise);
      };
      let payment;
      try {
        payment = await paymentRequest.show();
      } catch (e) {
        console.error(e);
        if (e?.name === 'AbortError') {
          paymentFailed(false);
        } else {
          paymentComplete(false, item, amount, requestId);
        }
        return;
      }
      ({ payerName, payerEmail, requestId, methodName, details } = payment);
      if (details.errorCode) {
        console.error(details.statusMessage);
        paymentComplete(false, item, amount, requestId);
        return;
      }
      try {
        const token = getSelectedPaymentOption() === 'apple' ? details.token : details.paymentMethodToken.token;
        const success = await completePayment(item, amount, payerName, payerEmail, requestId, methodName, token);
        payment.complete(success ? 'success' : 'fail');
      } catch (err) {
        payment.complete('fail');
        paymentFailed();
        console.error(err);
        ReactGA.exception({
          description: String(err),
        });
      }
    } else if (getSelectedPaymentOption() === 'googlejs') {
      const googlePaymentRequest = GetGooglePaymentRequest(state.config, state.charity, item, amount);
      const googlePayClient = GetGooglePaymentsClient();
      let payment;
      try {
        payment = await googlePayClient.loadPaymentData(googlePaymentRequest);
      } catch (e) {
        if (e?.t2?.code !== 20) {
          let error = `${e?.t2?.statusCode || 'Unknown error'} [${e?.t2?.code || '-'}]`;
          toast.error(`Something went wrong with Google Pay. Please try another payment method. (${error})`);
        }
        paymentFailed(false);
        console.error(e);
        return;
      }

      try {
        // TODO: do we need to show a spinner/UI here?
        // Note: no card name :(
        const reference = uuid.v1();
        const name = payment.paymentMethodData?.info?.billingAddress?.name || 'Google Pay User';
        const email = payment.email;
        const token = payment.paymentMethodData?.tokenizationData?.token;
        if (!token) {
          throw new Error('No token returned from Google Pay.');
        }
        await completePayment(item, amount, name, email, reference, 'https://google.com/pay', token);
      } catch (err) {
        paymentFailed();
        console.error(err);
        ReactGA.exception({
          description: String(err),
        });
      }
    } else {
      throw new Error('unsupported payment type');
    }
  }

  const PresetAmountsBlock = (
    <AnimateHeight animateOpacity={true} duration={ANIMATION_LENGTH} height={!customAmountContainer ? 'auto' : 0}>
      {!state.isProduction && (
        <div className="demo-only">
          <p className="align-centre">
            <b>DEMO ONLY</b>
          </p>
        </div>
      )}
      {donationPresets.map((preset, index) => {
        return (
          <button
            value={preset.amount}
            key={index}
            onClick={() => doPayment(preset.name, preset.amount)}
            className={`button choice-btn flex flex-center ${preset.color[0] !== '#' ? preset.color : ''} `}
            style={preset.color[0] === '#' ? { background: preset.color } : {}}
          >
            <span className="presetAmount left">$ {preset.amount}</span>
            {preset.name && <span className="presetName right grow">{preset.name}</span>}
          </button>
        );
      })}
      {!state.campaign.disableCustomAmount && (
        <div>
          <hr />
          <button
            className="button choice-btn choice-custom"
            onClick={() => {
              setCustomAmountContainer(true);
            }}
          >
            Choose Custom Amount
          </button>
        </div>
      )}
    </AnimateHeight>
  );

  const CustomAmountBlock = (
    <AnimateHeight animateOpacity={true} duration={ANIMATION_LENGTH} height={customAmountContainer ? 'auto' : 0}>
      <div className="custom-container">
        {!state.isProduction && (
          <div className="demo-only">
            <p className="align-centre">
              <b>DEMO ONLY</b>
            </p>
          </div>
        )}
        <div className="amount-container">
          <label htmlFor="custom-amount">$</label>
          <input
            id="custom-amount"
            value={customAmount}
            onChange={(e) => updateCustomAmount(e.target.value)}
            name="customDonationAmount"
            inputMode="numeric"
          />
        </div>
        <hr />
        <div className="custom-actions">
          <button className="button choice-btn back-button" onClick={() => setCustomAmountContainer(false)}>
            Back
          </button>
          <button
            type="submit"
            className="button choice-btn confirm-button primary-bg"
            onClick={(e) => doPayment(
              `Custom Amount Gift of ${FormatCurrency(customAmount, state.charity.currency || 'AUD', state.charity.locale || 'en-AU')}`,
              customAmount,
            )}
          >
            <span className="confirm-donate-text">Donate</span>
            <span className="confirm-amount-text">
              {FormatCurrency(customAmount, state.charity.currency || 'AUD', state.charity.locale || 'en-AU')}
            </span>
          </button>
        </div>
      </div>
    </AnimateHeight>
  );

  const PaymentAmountChooserBlock = (
    <AnimateHeight
      animateOpacity={true}
      duration={ANIMATION_LENGTH}
      height={!choosePaymentOptionVisible && !processing ? 'auto' : 0}
    >
      {errorMessage && (
        <div className="error-message">
          <div className="inner-container">
            <p>{errorMessage}</p>
          </div>
        </div>
      )}
      <div className="inner-container position-relative">
        <div className="modal-close" onClick={closeModal}>
          <img src={cross} srcSet={`${cross} 1x, ${cross2x} 2x, ${cross3x} 3x`} className="" alt="close" />
        </div>
        <div className="modal-header">
          <img
            src={heart}
            srcSet={`${heart} 1x, ${heart2x} 2x, ${heart3x} 3x`}
            className="heart image-pulse"
            alt="heart"
          />
          <img src={hand} srcSet={`${hand} 1x, ${hand2x} 2x, ${hand3x} 3x`} className="hand" alt="heart" />
          {props.paymentOptions.length > 1 ? (
            <div className="button current-payment-option" onClick={() => setChoosePaymentOptionVisible(true)}>
              {getDonateWithElement()}
            </div>
          ) : (
            getDonateWithElement()
          )}
        </div>

        <div className="modal-body">
          {PresetAmountsBlock}
          {CustomAmountBlock}

          <hr />
          <p className="donation-agreement">
            Upon confirming your donation, you agree with <br />{' '}
            <ReactGA.OutboundLink
              eventLabel="TermsAndConditions"
              className="primary-color"
              to="/terms-and-conditions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Donation Point Go Terms &amp; Conditions
            </ReactGA.OutboundLink>
          </p>
        </div>
      </div>
    </AnimateHeight>
  );

  const ProcessingBlock = (
    <AnimateHeight
      className="inner-container position-relative"
      animateOpacity={true}
      duration={ANIMATION_LENGTH}
      height={processing ? 'auto' : 0}
    >
      <div className="modal-header">
        <p className="header-text">Just a moment...</p>
        {
          /* HCapture will replace Google's recaptcha so only load it if we need it. We need Google's recapture to run before hCapture is loaded so this element cannot be loaded until needed */
          showExtraCapture && <CaptureChallenge visible={showExtraCapture} onVerify={onExtraCaptureVerify} />
        }
      </div>
      {LoadingCubes}
    </AnimateHeight>
  );

  return (
    <div>
      <div className="donate-now-footer">
        <div className="donate-now-container inner-container">
          <button onClick={openModal} className="button donate-now-button primary-bg">
            Donate Now
          </button>
        </div>
        <div className="copyright">
          <img src={copyrightImage} alt="Powered By Donation Point Go Copyright Quest Payment Systems" />
        </div>
      </div>

      <div
        id="choicesModal"
        className={`Choices-modal ${modalVisible ? '' : 'Choices-modal-hidden'}`}
        onClick={() => (processing ? 'no op' : closeModal())}
      >
        <div className="Choices-modal-content" onClick={(e) => e.stopPropagation()}>
          <AnimateHeight
            className="inner-container"
            animateOpacity={true}
            duration={ANIMATION_LENGTH}
            height={choosePaymentOptionVisible && !processing ? 'auto' : 0}
          >
            <PaymentTypePicker
              setPaymentOption={updatePaymentOption}
              options={props.paymentOptions}
              selectedOption={getSelectedPaymentOption()}
            />
          </AnimateHeight>
          {PaymentAmountChooserBlock}
          {ProcessingBlock}
        </div>
      </div>

      {creditCardFormAmount ? (
        <CreditCardForm
          charity={charityKey}
          campaign={campaignKey}
          amount={creditCardFormAmount}
          currency={state.charity.currency || 'AUD'}
          onClose={() => hideCreditCardForm()}
          onComplete={creditCardFormComplete}
          showExtraCapture={showExtraCapture}
          onExtraCaptureVerify={onExtraCaptureVerify}
        />
      ) : undefined}
    </div>
  );
}

export default Choices;
