import React from 'react';
export default (<div className="sk-cube-grid">
  <div className="sk-cube sk-cube1 primary-bg"></div>
  <div className="sk-cube sk-cube2 primary-bg"></div>
  <div className="sk-cube sk-cube3 primary-bg"></div>
  <div className="sk-cube sk-cube4 primary-bg"></div>
  <div className="sk-cube sk-cube5 primary-bg"></div>
  <div className="sk-cube sk-cube6 primary-bg"></div>
  <div className="sk-cube sk-cube7 primary-bg"></div>
  <div className="sk-cube sk-cube8 primary-bg"></div>
  <div className="sk-cube sk-cube9 primary-bg"></div>
</div>);
