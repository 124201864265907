import React, { useEffect } from 'react';
import { Store, loadCampaign } from './Store';
import Campaign from './Campaign';
import ThankYou from './ThankYou';
import Barcode from './Barcode';
import LoadingScreen from './LoadingScreen';
import GetPaymentOptions from './Payments/GetPaymentOptions';
import ReactGA from 'react-ga';
import './App.css';
import logoBlack from './Assets/img/logoBlack.png';
import logoBlack2x from './Assets/img/logoBlack@2x.png';
import logoBlack3x from './Assets/img/logoBlack@3x.png';
import questLogoBlack from './Assets/img/questLogoBlack.png';
import questLogoBlack2x from './Assets/img/questLogoBlack@2x.png';
import questLogoBlack3x from './Assets/img/questLogoBlack@3x.png';
import headerLogo from './Assets/img/header-logo.png';
import headerLogo2x from './Assets/img/header-logo@2x.png';
import headerLogo3x from './Assets/img/header-logo@3x.png';

const initialiseCharityTracker = (charity) => {
  if (charity.googleAnalyticsId) {
    ReactGA.addTrackers([{
      trackingId: charity.googleAnalyticsId,
      gaOptions: {
        name: 'charity',
        custom_map: {
          'dimension1': 'campaign',
        }
      }
    }]);
  }
}

function App() {
  const { state, dispatch } = React.useContext(Store);
  const [paymentOptions, setPaymentOptions] = React.useState(['form']);

  React.useEffect(() => {
    let campaignSlug = window.location.pathname.slice(1);

    if (!campaignSlug) {
      dispatch({
        type: 'SHOW_BARCODE_SCREEN',
      });
      return;
    }

    loadCampaign(dispatch, campaignSlug);
  }, [dispatch]);

  React.useEffect(() => {
    // Pre-load payment type based on new charity
    setPaymentOptions(['form']);
    if (state.config && state.charity) {
      GetPaymentOptions(state.config, state.charity)
        .then((options) => {
          setPaymentOptions(options);
          ReactGA.set({'dimension4': options.join(', ')});
        })
        .catch((e) => {
          console.error(`Something went wrong detecting the payment options: ${e}`);
        });
    }
  }, [state.charity, state.config]);

  // Google analytics
  useEffect(() => {
    ReactGA.initialize(state.isProduction ? 'UA-73322308-3' : 'UA-73322308-4', {
      gaOptions: {
        custom_map: {
          'dimension1': 'campaign',
          'dimension2': 'charity',
          'dimension3': 'paymentType',
          'dimension4': 'supportedPaymentOptions',
          'dimension5': 'currency',
        }
        // NOTE: custom_map doesn't work in ReactGA
        // - need to use dimensionX instead in set/event calls
      }
    });
  }, [dispatch]); // Run once at startup

  useEffect(() => {
    if (!state.currentPage) {
      return;
    }
    if (state.currentPage === 'barcode') {
      ReactGA.pageview('/');
    } else if (state.currentPage === 'error') {
      ReactGA.exception({
        description: state.error,
      });
    } else if (state.currentPage === 'thank-you') {
      ReactGA.modalview('/thank-you');
    } else if (state.currentPage === 'campaign') {
      initialiseCharityTracker(state.charity);
      ReactGA.set({'dimension2': (state.charity||{}).name});
      ReactGA.set({'dimension1': state.campaignSlug}, ['charity']);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [state.currentPage]); // Run every time the current page changes

  function isInstalledApp() {
    return navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
  }

  function appHeader() {
    return <div className="app-header primary-bg">
      <a href="/">
        <img alt="Donation Point Go"
          src={headerLogo}
          srcSet={`${headerLogo} 1x, ${headerLogo2x} 2x, ${headerLogo3x} 3x`} />
      </a>
    </div>
  }

  return (
    <div className="App">
      {isInstalledApp() && appHeader()}

      {state.currentPage === 'barcode' && <Barcode />}

      {state.currentPage === 'loading' && <LoadingScreen />}

      {state.currentPage === 'error' && (
        <div className="App-container breakout">
          <div>
            <img
              src={logoBlack}
              srcSet={`${logoBlack} 1x, ${logoBlack2x} 2x, ${logoBlack3x} 3x`}
              className="dpg-logo-black"
              alt="Dpgo"
            />
          </div>
          <p>{state.error}</p>
          <div>
            <img alt="Powered by Quest Payment Systems" className="quest-logo"
              src={questLogoBlack}
              srcSet={`${questLogoBlack} 1x, ${questLogoBlack2x} 2x, ${questLogoBlack3x} 3x`} />
          </div>
        </div>
      )}

      {state.currentPage === 'campaign' && (
        <div>
          <Campaign
            charity={state.charity}
            campaign={state.campaign}
            statistics={state.statistics}
            paymentOptions={paymentOptions}
          />
        </div>
      )}

      {state.currentPage === 'thank-you' && <ThankYou donorEmail={state.donorEmail} />}
    </div>
  );
}

export default App;
