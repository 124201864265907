import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { Blurhash } from 'react-blurhash';
import moment from 'moment';
import './Campaign.scss';
import Stats from './Stats';
import { Store } from './Store';
import logoBlack from './Assets/img/logoBlack.png';
import logoBlack2x from './Assets/img/logoBlack@2x.png';
import logoBlack3x from './Assets/img/logoBlack@3x.png';
import questLogoBlack from './Assets/img/questLogoBlack.png';
import questLogoBlack2x from './Assets/img/questLogoBlack@2x.png';
import questLogoBlack3x from './Assets/img/questLogoBlack@3x.png';
import Choices from './Choices';

function Campaign(props) {
  const { state } = React.useContext(Store);
  const [campaignImageLoaded, setCampaignImageLoaded] = useState(false);
  function campaignImageDidLoad() {
    setCampaignImageLoaded(true);
  }

  const startAtDate = props.campaign.startAtDate ? new Date(props.campaign.startAtDate._seconds * 1000) : null;
  const closeAtDate = props.campaign.closeAtDate ? new Date(props.campaign.closeAtDate._seconds * 1000) : null;

  const activeCampaign = !props.campaign.closed && (
    <div className="body">
      <div className="campaignHeadline">
        <h2>{props.campaign.title || props.campaign.tagline}</h2>
      </div>

      {props.campaign.showStats && <Stats statistics={props.statistics} campaign={props.campaign} currency={props.charity.currency || 'AUD'} locale={props.charity.locale || 'en-AU'} />}
      {props.campaign.showStats && <hr />}

      <div className="campaignDetails">
        {!state.isProduction && (
          <div>
            <p className="align-centre">
                <b>DEMO ONLY : </b>
                Transactions attempted using this demonstration are in simulation only, and your card will not be
                charged
            </p>
            <hr />
          </div>
        )}
        <span className="details-heading primary-color">{props.campaign.descriptionTitle || 'Campaign Details'}</span>

        <div
          className="details-body"
          dangerouslySetInnerHTML={{ __html: props.campaign.markedDescription }}
        ></div>

        
        <div className="details-body mini-text">
          All donations are in {props.charity.currency || 'AUD'}.
        </div>

        
        <span className="details-heading primary-color">Charity Details</span>
        <div className="details-body">
          {props.charity.tradingName &&
          <div className="charity-info-line">
            <span className="primary-color">Trading Name: </span> {props.charity.tradingName}<br />
            <span className="mini-text">This name will appear on your card-holder statement</span>
          </div>
          }
          {props.charity.legalName &&
          <div className="charity-info-line">
            <span className="primary-color">Legal Name: </span> {props.charity.legalName}<br />
          </div>
          }
          {props.charity.abnNumber &&
          <div className="charity-info-line">
            <span className="primary-color">ABN: </span> {props.charity.abnNumber}
          </div>
          }
          {props.charity.nzbnNumber &&
          <div className="charity-info-line">
            <span className="primary-color">NZBN: </span> {props.charity.nzbnNumber}
          </div>
          }
          {props.charity.phone &&
          <div className="charity-info-line">
            <span className="primary-color">Phone Number: </span>
            <a className="primary-color" href={`tel:${props.charity.phone}`}>{props.charity.phone}</a> 
          </div>
          }
          {props.charity.email &&
          <div className="charity-info-line">
            <span className="primary-color">Email: </span>
            <a className="primary-color" href={`mailto:${props.charity.email}`}>{props.charity.email}</a> 
          </div>
          }
          {props.charity.address &&
          <div className="charity-info-line">
            <span className="primary-color">Address: </span>
            {props.charity.address}
          </div>
          }
          {props.charity.link &&
          <div className="charity-info-line">
            <span className="primary-color">Website: </span>
            <ReactGA.OutboundLink
              eventLabel="CharityWebpage"
              className="primary-color"
              to={props.charity.link}
              target="_blank"
              rel="noopener"
            >{props.charity.link}</ReactGA.OutboundLink> 
          </div>
          }
          {props.charity.privacyPolicy &&
          <div className="charity-info-line">
            You can find our <ReactGA.OutboundLink
              eventLabel="PrivacyPolicy"
              className="primary-color"
              to={props.charity.privacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy here
            </ReactGA.OutboundLink>.
          </div>
          }
          <div className="charity-info-line">
            You can find our <ReactGA.OutboundLink
              eventLabel="PrivacyPolicy"
              className="primary-color"
              to={props.charity.refundPolicy || "/refund-policy.html"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Refund Policy here
            </ReactGA.OutboundLink>.
          </div>
          <div className="charity-info-line">
            You can find the <ReactGA.OutboundLink
              eventLabel="TermsAndConditions"
              className="primary-color"
              to="/terms-and-conditions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Donation Point Go Terms &amp; Conditions here
            </ReactGA.OutboundLink>.
          </div>
        </div>
      </div>
      <Choices paymentOptions={props.paymentOptions} />
    </div>
  );

  const closedCampaign = props.campaign.closed && (
    <div className="body">
      <div className="campaign-closed">
        <div className="tag-line">{props.campaign.title || props.campaign.tagline}</div>
        <div>
          <img
            src={logoBlack}
            srcSet={`${logoBlack} 1x, ${logoBlack2x} 2x, ${logoBlack3x} 3x`}
            className="dpg-logo-black"
            alt="Dpgo"
          />
        </div>
        <div>
          <p>Sorry, this campaign is not currently active.</p>
          {!props.campaign.hasStarted && startAtDate && <p>
            Please check back on {moment(startAtDate).format('dddd Do MMMM YYYY [at] h:mm:ss a')}.
          </p>
          }
          {props.campaign.hasFinished && closeAtDate && <p>
            This campaign finished on {moment(closeAtDate).format('dddd Do MMMM YYYY [at] h:mm:ss a')}.
          </p>}
          {props.charity.link && <p>
            For more information please visit <ReactGA.OutboundLink
              eventLabel="CharityWebpage"
              to={props.charity.link}
              target="_blank"
              rel="noopener"
            >{props.charity.link}</ReactGA.OutboundLink>.
          </p>}
        </div>
      </div>
      <div className="align-centre">
        <img alt="Powered by Quest Payment Systems" className="quest-logo"
          src={questLogoBlack}
          srcSet={`${questLogoBlack} 1x, ${questLogoBlack2x} 2x, ${questLogoBlack3x} 3x`} />
      </div>
    </div>
  );

  return (
    <div className="Campaign Campaign-fade-in">
      <div className="header">
        {!campaignImageLoaded &&
        <Blurhash
          className="display-block position-absolute"
          hash={props.campaign.headerImageBlurHash || 'LEHV6nWB2yk8pyo0adR*.7kCMdnj'}
          width="100%"
          height={300}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />}
        <img
          className={`campaign-image ${campaignImageLoaded ? 'campaign-image-loaded' : ''}`}
          src={props.campaign.headerImage}
          srcSet={props.campaign.srcSet && (props.campaign.srcSet.length === 3) ? `${props.campaign.srcSet[0]} 1x, ${props.campaign.srcSet[1]} 2x, ${props.campaign.srcSet[2]} 3x`: undefined}
          alt={props.campaign.name}
          onLoad={campaignImageDidLoad}
        />
        {props.charity.link ? (
          
          <ReactGA.OutboundLink
            eventLabel="CharityWebpage"
            className="campaign-overlay"
            to={props.charity.link}
            target="_blank"
            rel="noopener"
          >
            <div className="bigItem">
              <div className="charity-title">{props.charity.name}</div>
              <div className="charity-location">{props.charity.location}</div>
            </div>
            <div className="smallItem">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAANCAYAAACUwi84AAAAAXNSR0IArs4c6QAAAEtJREFUGBlj+P//vwIQHwDRDNgAVBJI/X+AVRFIECoJpAaNIgeQY6DgAIrHgIK4HUwjSZADgEYfgDroAcgaFEdBFYAcBlKEKQlUAACGOM/HBmQf8AAAAABJRU5ErkJggg=="
                alt=">"
                className="caret"
              />
            </div>
          </ReactGA.OutboundLink>
        ) : (
          <div className="campaign-overlay">
            <div className="bigItem">
              <div className="charity-title">{props.charity.name}</div>
              <div className="charity-location">{props.charity.location}</div>
            </div>
          </div>
        )}
      </div>
      {activeCampaign}
      {closedCampaign}
    </div>
  );
}

export default Campaign;
