import PayConfig from './PayConfig';

export default function (charityConfig, charity, item, amount, surcharge = 0) {
  if (typeof amount !== 'number') {
    throw new Error('Must provide a number for amount.');
  }
  let total = (amount + surcharge) / 100;
  amount /= 100;
  surcharge /= 100;
  let googleConfig = PayConfig.getGooglePay(charity.name, charityConfig);
  googleConfig.transactionInfo = {
    totalPriceStatus: 'FINAL',
    checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE',
    totalPriceLabel: 'Total Donation',
    totalPrice: total.toFixed(2),
    currencyCode: charity.currency || 'AUD',
    countryCode: charity.countryCode || 'AU',
    displayItems: [
      {
        label: `Donation: ${item}`,
        type: 'LINE_ITEM',
        price: amount.toFixed(2),
      },
    ],
  };
  googleConfig.emailRequired = true;
  // no option for payer name...


  if (surcharge) {
    googleConfig.transactionInfo.displayItems.append({
      label: `Covering surcharge`,
      type: 'LINE_ITEM',
      price: surcharge.toFixed(2),
    });
  }
  
  return googleConfig;
}
