import axios from 'axios';
import * as EventEmitter from 'events';
import isProduction from './IsProduction';

export const fatZebraOrigin = isProduction ? 'https://paynow.pmnts.io' : 'https://paynow.pmnts-sandbox.io';

export const getIframeData = async (charity, campaign, amount, currency = 'AUD') => {
  const response = await axios.post('/api/startCreditCardPayment', {
    charity,
    campaign,
    amount,
    currency
  });

  if (response.status < 200 || response.status >= 300) {
    throw new Error(`failed to get Fat Zebra redirect (HTTP status ${response.status})`);
  }

  return response.data;
};

export const fatZebraEvent = new EventEmitter();

// The following code originally came from Fat Zebra's hosted page https://docs.cloudpayments.io/reference#hosted-payment-pages
const messageListener = (event) => {
  if (fatZebraOrigin !== event.origin) {
    return;
  }

  let payload = event.data;
  if (typeof event.data == 'string') {
    if (/\[object/i.test(event.data)) {
      alert('Sorry, it looks like there has been a problem communicating with your browser...'); // Raised if the serialization failed
    }
    // Deserialize into an object
    const pairs = payload.split('&');
    payload = {};
    for (let i = 0; i < pairs.length; i++) {
      const element = pairs[i];
      const kv = element.split('=');
      payload[kv[0]] = kv[1];
    }
  }

  switch (payload.message) {
    case 'transaction.complete':
    // intentionally falls through

    case 'transaction.error':
      fatZebraEvent.emit('paymentComplete', event.data.data);
      // Handle the transaction complete message.
      // Payload will be in e.data.data (eg. payload.data.r == 1)
      return true;

    case 'transaction.processing':
      // Handle the processing of the transaction - implementation optional.
      return true;

    case 'transaction.cancelled':
      fatZebraEvent.emit('paymentCancelled', event.data.data);
      // Handle the transaction being cancelled (i.e. show message, re-display the window etc).
      return true;

    case 'form.invalid':
      const errors = event.data.errors.split(', ');
      fatZebraEvent.emit('formValidation', errors);
      break;

    case 'form.valid':
      fatZebraEvent.emit('formValidation', []);
      break;

    default:
      // intentionally ignored
      break;
  }
};

export const installFatZebraListener = () => {
  if (window.addEventListener) {
    window.addEventListener('message', messageListener);
  } else {
    window.attachEvent('onmessage', messageListener);
  }
};
