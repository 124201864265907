import isProduction from '../IsProduction';

export default {
  getApplePay(config, charity) {
    return {
      version: 3,
      merchantIdentifier: config.appleMerchantId,
      merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
      supportedNetworks: ['masterCard', 'visa'], // amex and discover available
      countryCode: charity.countryCode || 'AU',
    };
  },

  getGooglePayV1(charityName, config) {
    return {
      environment: isProduction ? 'PRODUCTION' : 'TEST',
      apiVersion: 1,
      merchantId: isProduction ? 'BCR2DN6T7O4K7AS6' : '12345678901234567890',
      merchantInfo: {
        merchantName: charityName,
      },
      allowedPaymentMethods: ['CARD', 'TOKENIZED_CARD'],
      paymentMethodTokenizationParameters: {
        tokenizationType: 'PAYMENT_GATEWAY',
        parameters: {
          gateway: 'fatzebra',
          gatewayMerchantId: config.googleMerchantId,
        },
      },
      cardRequirements: {
        allowedCardNetworks: ['MASTERCARD', 'VISA'],
      },
    };
  },

  getGooglePay(charityName, config) {
    return {
      environment: isProduction ? 'PRODUCTION' : 'TEST',
      apiVersion: 2,
      apiVersionMinor: 0,
      merchantInfo: {
        merchantName: charityName,
        merchantId: isProduction ? 'BCR2DN6T7O4K7AS6' : '12345678901234567890',
      },
      allowedPaymentMethods: [{
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'], // 'PAN_ONLY',  ?
          allowedCardNetworks: ['MASTERCARD', 'VISA'],
          billingAddressRequired: true,
          billingAddressParameters: {
            format: 'MIN',
          }
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'fatzebra',
            gatewayMerchantId: config.googleMerchantId,
          },
        },
      }],
    };
  }
}
