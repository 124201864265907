import React from 'react';
import toast from 'react-hot-toast';
import './PaymentTypePicker.scss';

import googlePayMark from './Assets/img/google-pay-mark.svg';
import applePayMark from './Assets/img/apple-pay-mark.svg';
import formPaymentMark from './Assets/img/credit-card.svg';
import check from './Assets/img/check.svg';
import cross from './Assets/img/cross.png';
import cross2x from './Assets/img/cross@2x.png';
import cross3x from './Assets/img/cross@3x.png';
import copy from './Assets/img/copy-small.png';
import copy2x from './Assets/img/copy-small@2x.png';
import copy3x from './Assets/img/copy-small@3x.png';

function PaymentTypePicker(props) {
  const shareUrl = `${window.location.origin}${window.location.pathname}`;

  function chooseOption(option) {
    props.setPaymentOption(option);
  }

  function getOptionContents(option) {
    switch(option) {
      case 'googlejs': return <>
        <img className="payment-mark" src={googlePayMark} alt='Google Pay' width="48" />
        <span>Google Pay</span>
      </>
      case 'apple': return <>
        <img className="payment-mark" src={applePayMark} alt='Apple Pay' width="48" />
        <span>Apple Pay</span>
      </>
      case 'form': return <>
        <img className="payment-mark" src={formPaymentMark} alt='Credit Card' width="48" />
        <span>Credit Card</span>
      </>
      case 'safari': return <>
        <img className="payment-mark" src={applePayMark} alt='Apple Pay' width="48" />
        <span>Apple Pay - please open this page in Safari</span>
      </>
      default: return ''
    }
  }
  
  function copyUrl() {
    navigator.clipboard.writeText(shareUrl).then(() => {
      toast.success(<span>Copied <i>{shareUrl}</i> to clipboard</span>);
    }).catch(() => {
      toast.error(`Couldn't copy to clipboard`);
    })
  }

  return (
    <div className="PaymentTypePicker">
      <div className="modal-close" onClick={() => chooseOption(props.selectedOption)}>
        <img src={cross} srcSet={`${cross} 1x, ${cross2x} 2x, ${cross3x} 3x`} className="" alt="close" />
      </div>
      <div className="modal-header">
        <div className="header-text">How would you like to donate?</div>
      </div>
      {props.options.map((option) => {
        if (option === 'safari') {
          return (
            <div className="payment-option payment-option-safari" onClick={() => copyUrl()}>
              <div className="flex">
                {getOptionContents('safari')}
              </div>
              <button className="copy-button">
                <img
                  src={copy}
                  srcSet={`${copy} 1x, ${copy2x} 2x, ${copy3x} 3x`}
                  width="24" height="24"
                  alt="Copy"
                />
                Copy URL
              </button>
            </div>
          )
        } // else
        return <div key={option} className="payment-option" onClick={() => chooseOption(option)}>
          <div className="flex">
            {props.selectedOption === option &&
            <img className="check" src={check} alt="Selected" />}
            {getOptionContents(option)}
          </div>
        </div>
      })}
    </div>
  );
}

export default PaymentTypePicker;
