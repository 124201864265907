import React from 'react';
import LoadingCubes from './LoadingCubes';

const LoadingScreen = () => (
  <div className="initial-loader">
    {LoadingCubes}
  </div>
);

export default LoadingScreen;
