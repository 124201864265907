import React, { useRef, useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import './Barcode.scss';
import welcomeText from './Assets/img/welcomeText.png';
import welcomeText2x from './Assets/img/welcomeText@2x.png';
import welcomeText3x from './Assets/img/welcomeText@3x.png';
import scannerBlue from './Assets/img/scannerBlue.png';
import scannerBlue2x from './Assets/img/scannerBlue@2x.png';
import scannerBlue3x from './Assets/img/scannerBlue@3x.png';
import scannerWhite from './Assets/img/scannerWhite.svg';
import logoWhite from './Assets/img/logoWhite.png';
import logoWhite2x from './Assets/img/logoWhite@2x.png';
import logoWhite3x from './Assets/img/logoWhite@3x.png';

const VideoDisabledUi = (props) => {
  return (
    <div className="vertical-spread">
      <div>&nbsp;</div>

      <div>
        <img
          className="scanner-blue"
          src={scannerBlue}
          srcSet={`${scannerBlue} 1x, ${scannerBlue2x} 2x, ${scannerBlue3x} 3x`}
          alt="Logo"
        />
      </div>

      <div>
        <img
          className="welcome-text"
          src={welcomeText}
          srcSet={`${welcomeText} 1x, ${welcomeText2x} 2x, ${welcomeText3x} 3x`}
          alt="Donation Point Go"
        />
      </div>

      <p>
        If you've found a Donation Point Go QR code then tap the button below to use your mobile devices camera to scan it.<br /><br />
        If you'd like to found out more about the Donation Point Go platform then please visit <a href="https://donationpointgo.com/">https://donationpointgo.com/</a>.
      </p>

      <button className="button" onClick={props.scan}>
        Scan a QR Code
      </button>
      <div>&nbsp;</div>
    </div>
  );
};

const VideoEnabledUi = () => (
  <div className="vertical-spread white-overlay">
    <div>&nbsp;</div>

    <div>
      <img
        className="logo-white"
        src={logoWhite}
        srcSet={`${logoWhite} 1x, ${logoWhite2x} 2x, ${logoWhite3x} 3x`}
        alt="Donation Point Go"
      />
    </div>

    <div>
      <img className="scanner-white" src={scannerWhite} alt="Scan region" />
    </div>

    <div>Please position the QR code inside the frame above and keep your device steady.</div>

    <div>&nbsp;</div>
  </div>
);

const processUrl = async (qrCode) => {
  const validDomains = ['staging.dpgo.io', 'dpgo.io'];
  const qrCodeUrl = new URL(qrCode);
  const host = qrCodeUrl?.host;
  if (!host || validDomains.indexOf(host) < 0) {
    console.log('invalid qr code');
    return false;
  }

  ReactGA.event({
    category: 'Scan',
    action: `Scanned code`,
    label: qrCode,
  });
  location.assign(qrCode); // eslint-disable-line
  return true;
};

const Barcode = () => {
  const canvasRef = useRef();
  const videoRef = useRef();
  const [videoEnabled, setVideoEnabled] = useState(false);

  useEffect(() => {
    if (!videoRef.current || !canvasRef.current) {
      return;
    }

    const worker = new Worker('./barcode.worker.js');
    (async () => {
      const video = videoRef.current;
      const canvasElement = canvasRef.current;
      var canvas = canvasElement.getContext('2d');
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
      video.srcObject = stream;
      video.setAttribute('playsinline', true);
      video.play();

      const animationFrame = () => {
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          canvasElement.height = video.videoHeight;
          canvasElement.width = video.videoWidth;
          canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
          var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);

          worker.postMessage({
            imageData: imageData.data,
            imageWidth: imageData.width,
            imageHeight: imageData.height,
          });
        } else {
          requestAnimationFrame(animationFrame);
        }
      };

      worker.onmessage = async (event) => {
        try {
          const code = event.data;

          if (code && (await processUrl(code))) {
            return;
          }
        } catch (e) {
          console.error('failed to process url', e);
        }

        requestAnimationFrame(animationFrame);
      };
      requestAnimationFrame(animationFrame);
    })();
  }, [videoEnabled]);

  const videoUi = (
    <div className={`center-container video-container${videoEnabled ? '' : ' hidden'}`}>
      <canvas className="hidden" ref={canvasRef} />
      {videoEnabled && <video ref={videoRef} />}
    </div>
  );

  return (
    <div className="barcode">
      {videoUi}
      {/* preload enabled UI otherwise you can see the images load */}
      <div className={videoEnabled ? '' : 'hidden'}>
        <VideoEnabledUi />
      </div>
      {!videoEnabled && <VideoDisabledUi scan={() => setVideoEnabled(true)} />}
    </div>
  );
};

export default Barcode;
