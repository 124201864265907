import PayConfig from './PayConfig';

export default function (charityConfig, charity, item, amount, surcharge = 0) {
  if (typeof amount !== 'number') {
    throw new Error('Must provide a number for amount.');
  }

  const supportedPaymentMethods = [
    // TODO: remove once we're sure we won't support Google using PaymentRequest at all
    {
      supportedMethods: 'https://google.com/pay',
      data: PayConfig.getGooglePayV1(charity.name, charityConfig),
    },
    {
      supportedMethods: 'https://apple.com/apple-pay',
      data: PayConfig.getApplePay(charityConfig, charity),
    },
  ];

  const paymentDetails = {
    total: {
      label: 'Total Donation',
      amount: {
        currency: charity.currency || 'AUD',
        value: amount + surcharge,
      },
    },
    displayItems: [
      {
        label: `Donation: ${item}`,
        amount: {
          currency: charity.currency || 'AUD',
          value: amount,
        },
      },
    ],
  };
  if (surcharge) {
    paymentDetails.displayItems.append({
      label: `Covering surcharge`,
      amount: {
        currency: charity.currency || 'AUD',
        value: surcharge,
      },
    });
  }
  const options = {
    requestPayerName: true,
    requestPayerEmail: true,
  };
  
  return new window.PaymentRequest(supportedPaymentMethods, paymentDetails, options);
}
