import React, { useState } from 'react';
import ReactGA from 'react-ga';
import toast from 'react-hot-toast';
import './ThankYou.scss';
// Assets set for share icons
import facebook from './Assets/img/facebook.png';
import facebook2x from './Assets/img/facebook@2x.png';
import facebook3x from './Assets/img/facebook@3x.png';
import twitter from './Assets/img/twitter.png';
import twitter2x from './Assets/img/twitter@2x.png';
import twitter3x from './Assets/img/twitter@3x.png';
import share from './Assets/img/share.png';
import share2x from './Assets/img/share@2x.png';
import share3x from './Assets/img/share@3x.png';
import copy from './Assets/img/copy.png';
import copy2x from './Assets/img/copy@2x.png';
import copy3x from './Assets/img/copy@3x.png';
import inbox from './Assets/img/inbox.png';
import inbox2x from './Assets/img/inbox@2x.png';
import inbox3x from './Assets/img/inbox@3x.png';
import questLogoBlack from './Assets/img/questLogoBlack.png';
import questLogoBlack2x from './Assets/img/questLogoBlack@2x.png';
import questLogoBlack3x from './Assets/img/questLogoBlack@3x.png';

import { Store } from './Store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function Thankyou(props) {
  const { state } = React.useContext(Store);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [donorEmailAddress, setDonorEmailAddress] = useState(props.donorEmail || '');
  const disableOptin = state.campaign.disableOptin || false;
  const shareOverride = state.campaign.shareSnippetOverride || {};
  const shareTitle = shareOverride.title || state.campaign.title || state.campaign.tagline;
  const shareText = `I donated $${state.amount.toFixed(2)} to ${shareTitle}. You can too, here.`;
  const shareUrl = `${window.location.origin}${window.location.pathname}`;
  const twitterIntent = `https://twitter.com/intent/tweet/?text=${encodeURIComponent(
    shareText
  )}&url=${encodeURIComponent(shareUrl)}`;
  const facebookIntent = `https://www.facebook.com/dialog/share?app_id=214113516695981&display=popup&href=${encodeURIComponent(
    shareUrl
  )}`;

  // TODO: add interface to call the app install prompt
  function promptInstallApp() {
    if (window.beforeInstallPromptEvent) {
      window.beforeInstallPromptEvent.prompt();
    }
  }

  function shareWebIntent() {
    console.log(`Sharing web intent via navigator available`);
    navigator
      .share({
        title: shareTitle,
        text: shareText,
        url: shareUrl,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  }
  const OnSubscribeClick = () => {
    if (isSubscribing) {
      return;
    }

    setIsSubscribing(true);
    subscribe();
  };

  function subscribe() {
    fetch(`api/subscribe/${state.campaignKey}`, {
      method: 'POST',
      body: JSON.stringify({
        email: donorEmailAddress,
        charityId: state.charityKey,
        paymentResponseKey: state.paymentResponseKey,
      }),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
      .then((response) => {
        const success = response.status >= 200 && response.status < 300;
        setIsSubscribing(false);
        setIsSubscribed(success);
        if (!success) {
          setErrorMessage(response.body || 'Something went wrong. Please try again.');
        } else {
          ReactGA.event(
            {
              category: 'Subscription',
              action: 'Subscribed',
              label: state.campaignSlug,
            },
            ['charity']
          );
        }
      })
      .catch((err) => {
        setIsSubscribing(false);
        setIsSubscribed(false);
        setErrorMessage('Failed to subscribe. Please try with a valid email address.');
      });
  }

  function copyUrl() {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success(
          <span>
            Copied <i>{shareUrl}</i> to clipboard
          </span>
        );
      })
      .catch(() => {
        toast.error(`Couldn't copy to clipboard`);
      });
  }

  return (
    <div className="thank-you-container">
      <div className="flex-grid">
        <div className="col thank-you-box primary-bg">
          <div className="thank-you">
            <h2>Thank you.</h2>
            <p>Your donation receipt will be sent to your email.</p>
          </div>
        </div>
      </div>
      {!disableOptin && (
        <div className="flex-grid restricted">
          <div className="col">
            <div className="subscription-box">
              <div className="subscription-inner-box">
                <div className="email-icon">
                  <img src={inbox} srcSet={`${inbox} 1x, ${inbox2x} 2x, ${inbox3x} 3x`} className="inbox" alt="email" />
                </div>
                <div className="subscription-message">
                  {isSubscribed ? (
                    <p>Thanks for opting in for updates and news!</p>
                  ) : (
                    <p>Opt in to receive updates and news from {state.charity.name}.</p>
                  )}
                </div>
                <div className="donor-email-field">
                  <input
                    type="text"
                    name="email"
                    value={donorEmailAddress}
                    onChange={(e) => setDonorEmailAddress(e.target.value)}
                    disabled={isSubscribed}
                    placeholder="Your email address"
                  />
                </div>
                <button
                  className={isSubscribed ? 'button primary-border primary-color' : 'button primary-bg'}
                  onClick={OnSubscribeClick}
                  disabled={isSubscribed}
                >
                  {isSubscribing ? <FontAwesomeIcon icon={faSpinner} spin /> : isSubscribed ? 'Opted In ✔' : 'Opt In'}
                </button>
              </div>
              <p className="error">
                <small>{errorMessage}</small>
              </p>
            </div>
          </div>
        </div>
      )}
      {navigator.share ? (
        <button className="button web-share-btn" onClick={() => shareWebIntent(true)}>
          <img src={share} srcSet={`${share} 1x, ${share2x} 2x, ${share3x} 3x`} alt="share" /> Share your love
        </button>
      ) : (
        <div className="share-box">
          <h3>Share your love</h3>
          <div className="flex-grid">
            <div className="col">
              <a href={twitterIntent} target="_blank" rel="noopener noreferrer">
                <img
                  src={twitter}
                  srcSet={`${twitter} 1x, ${twitter2x} 2x, ${twitter3x} 3x`}
                  className="social-share-btn"
                  alt="twitter"
                />
              </a>
            </div>
            <div className="col">
              <a href={facebookIntent} target="_blank" rel="noopener noreferrer">
                <img
                  src={facebook}
                  srcSet={`${facebook} 1x, ${facebook2x} 2x, ${facebook3x} 3x`}
                  className="social-share-btn"
                  alt="facebook"
                />
              </a>
            </div>
            {navigator.clipboard && (
              <div className="col">
                <button className="btn-no-style" onClick={() => copyUrl()}>
                  <img
                    src={copy}
                    srcSet={`${copy} 1x, ${copy2x} 2x, ${copy3x} 3x`}
                    className="social-share-btn"
                    alt="Copy"
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <div>
        <img
          alt="Powered by Quest Payment Systems"
          className="quest-logo"
          src={questLogoBlack}
          srcSet={`${questLogoBlack} 1x, ${questLogoBlack2x} 2x, ${questLogoBlack3x} 3x`}
        />
      </div>
    </div>
  );
}

export default Thankyou;
