import './CreditCardForm.scss';
import React, { useState, useEffect } from 'react';
import { getIframeData, fatZebraEvent, fatZebraOrigin } from './fatZebraIFrame';
import { Store } from './Store';
import LoadingCubes from './LoadingCubes';


import visa from './Assets/img/visa-logo.png';
import visa2x from './Assets/img/visa-logo@2x.png';
import visa3x from './Assets/img/visa-logo@3x.png';
import mastercard from './Assets/img/mastercard-logo.png';
import mastercard2x from './Assets/img/mastercard-logo@2x.png';
import mastercard3x from './Assets/img/mastercard-logo@3x.png';

const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

const submitFatZebraForm = () =>
  new Promise((res) => {
    const unsubscribeList = [];
    const unsubscribe = () => unsubscribeList.forEach((entry) => entry());
    const onceWithUnsubscribe = (method, callback) => {
      fatZebraEvent.once(method, callback);
      unsubscribeList.push(() => fatZebraEvent.off(method, callback));
    };

    onceWithUnsubscribe('formValidation', (errors) => {
      if (errors.length > 0) {
        unsubscribe();
        res({ errors });
      }
    });

    onceWithUnsubscribe('paymentComplete', (result) => {
      unsubscribe();
      res({ result, errors: [] });
    });
    document.getElementById('creditCardIframe').contentWindow.postMessage('form.submit', fatZebraOrigin);
  });

export const CreditCardForm = (props) => {
  const { state } = React.useContext(Store);
  const [email, setEmail] = useState('');

  // After form validation Fat Zebra shows a loading screen which makes Quest fields and buttons look out of place.
  // If this value is true then we hide Quest controls.
  const [isProcessingPayment, setProcessingPayment] = useState(false);

  // form.valid is sent when the form is valid. Unfortunately it is not sent when the form is submitted, it is as the text becomes valid.
  // form.invalid is not always sent so this value can be wrong. By assuming it is right we reduce DOM flicker.
  const [isCreditCardFormValid, setIsCreditCardFormValid] = useState(false);

  const [errors, setErrors] = useState([]);
  const [iframeUrl, setIFrameUrl] = useState();

  const onComplete = props.onComplete || (() => {});
  const onClose = props.onClose || (() => {});

  const pay = async () => {
    const emailValidationError = 'Please enter a valid email address';

    // We need to validate our email separately if we think the Fat Zebra component is valid otherwise we will process the payment.
    // If we think the form is invalid, we submit the payment which should return the form errors and then append the email validation.
    if (isCreditCardFormValid && !validateEmail(email)) {
      setErrors([emailValidationError]);
      return;
    }

    if (isCreditCardFormValid) {
      setErrors([]);
      setProcessingPayment(true);
    }
    const form = await submitFatZebraForm();
    if (form.errors.length > 0) {
      setProcessingPayment(false);
      const errors = [...form.errors];
      if (!validateEmail(email)) {
        errors.push(emailValidationError);
      }

      setErrors(errors);
      return;
    }

    onComplete(Object.assign({}, form.result, { email }));
  };

  const intialiseZebraErrors = () => {
    submitFatZebraForm().catch(/* intentionally ignored */);
  }

  useEffect(() => {
    (async () => {
      try {
        setIFrameUrl((await getIframeData(props.charity, props.campaign, props.amount, props.currency))?.url);
      } catch (e) {
        console.error('failed to get iframe', e);
        alert('Unable to take credit card payment at this time');
        onClose();
      }
    })();

    const formValidation = (errors) => {
      console.log(errors);
      if (errors.length > 0) {
        setIsCreditCardFormValid(false);
      } else {
        setIsCreditCardFormValid(true);
      }
    };

    fatZebraEvent.on('formValidation', formValidation);
    return () => fatZebraEvent.off('formValidation', formValidation);
  }, [props.amount, props.charity, props.campaign, props.currency, onClose]);

  const creditCard = (
    <div>
      <iframe
        src={iframeUrl}
        className="creditcard-input"
        scrolling="no"
        id="creditCardIframe"
        title="credit-card"
        onLoad={intialiseZebraErrors}
      ></iframe>
      {isProcessingPayment ? undefined : (
        <div className="email">
          <label htmlFor="creditcard-email">Receipt Email</label>
          <div>
            <input id="creditcard-email" value={email} onChange={(e) => setEmail(e.target.value)}></input>
          </div>
        </div>
      )}

      {isProcessingPayment ? undefined : (
        <div className="pay-button-wrapper">
          <button className="button primary-bg" type="submit">
            Pay
          </button>
          <div style={{color: '#777', fontSize: '0.8rem', marginTop: 5}}>
            This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="overlay" onClick={onClose}>
      <form
        className="creditcard"
        onClick={(e) => e.stopPropagation()}
        onSubmit={async (e) => {
          e.preventDefault();
          await pay();
        }}
      >
        <div className="modal-close" onClick={onClose}>
          ✕
        </div>
        {!state.isProduction && 
        <div className="demo-card-details align-centre">
          <p className="demo-only"><b>DEMO ONLY</b></p>
          <p className="">Please use these card details below</p>
          <ul>
          <li>Card Number: 4111 1111 1111 1111</li>
          <li>Expiry: 12/{new Date().getFullYear() + 1} </li>
          <li>CVV: 123</li>
          </ul>
        </div>
        }
        <div className="pay-now-with align-centre">
          Pay Securely Now <img
            src={visa}
            srcSet={`${visa} 1x, ${visa2x} 2x, ${visa3x} 3x`}
            alt="VISA"
          /> <img
            src={mastercard}
            srcSet={`${mastercard} 1x, ${mastercard2x} 2x, ${mastercard3x} 3x`}
            alt="Mastercard"
          />
        </div>
        {errors.length === 0 ? undefined : (
          <div className="alert alert-error">
            Please fix the following issues:
            <ul>
              {errors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          </div>
        )}

        {iframeUrl ? creditCard : LoadingCubes}
      </form>
    </div>
  );
};
