import axios from 'axios';

const restGet = async (action, url) => {
  const result = await axios.get(url);
  return result.data;
};

export const getPageBySlug = (slug) => restGet('campaign', `/api/slug/${slug}`);

export const getRecaptchaToken = () => {
  return new Promise((resolve) => {
    // HCapture replaces Google's reCAPTCHA so we need to rename it
    /* eslint-disable no-undef */
    window.grecaptcha2 = window.grecaptcha2 || grecaptcha;
    grecaptcha2.ready(function () {
      grecaptcha2.execute('6LfLBnMeAAAAAKizz1mNCvEOWIWAc7HULDZ9eVuO', { action: 'submit' }).then(function (token) {
        resolve(token);
      });
    });
    /* eslint-enable no-undef */
  });
};
